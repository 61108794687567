import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';

import { CtIconComponent } from '../../ct-icon/ct-icon.component';
import { ChipComponent } from './chip.component';

@NgModule({
  imports: [CommonModule, MatRippleModule, MatChipsModule, CtIconComponent],
  declarations: [ChipComponent],
  exports: [ChipComponent],
})
export class ChipModule {}
