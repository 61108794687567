const automationTestingCompanies = [177330];
const ctCompanies = [100552];

const internalCompanies = [...automationTestingCompanies, ...ctCompanies];

export const featureFlagsConfig = {
  recordingControlEnabled: {
    companies: [...internalCompanies, 115197, 143043, 137923, 175701, 237403],
    users: [],
  },
  doNotShowAlreadyRegisteredPage: {
    companies: [],
    users: [243857, 15433, 266175],
  },
  anonymousCalls: {
    companies: [
      ...internalCompanies,
      16880,
      7182451,
      158951,
      152459,
      176151,
      219639,
      137923,
      204235,
      175701,
      237403,
      258913,
      252375,
      215988,
      252375,
      275663,
      160097,
      146693,
      244027,
      254463,
    ],
    users: [],
  },
  dialer: {
    companies: [...internalCompanies],
    users: [],
  },
};
