import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  decodeEntities,
  parseJwt,
} from '../../../_shared/helpers/helper-functions';
import { LoggerUtil } from '../../../_shared/utils/logger.util';
import { CookieService } from '../../cookie.service';
import { User } from '../../models/user';
import { SetCookieType } from '../../types/set-cookie.type';

export const FEATURES_KEYS = {
  REALTIME_TRIGGERS: 'is_webhook_enabled',
  CALL_MONITOR: 'is_call_monitor_enabled',
  SMART_DIALER: 'is_queue_dialer_enabled',
  USE_SMART_DIALER: 'use_queue_dialer',
  VOICEMAIL_DROPS: 'is_voicemail_drops_enabled',
  POWER_DIALER: 'is_power_dialer_enabled',
  WHATSAPP: 'is_whatsapp_enabled',
};

@Injectable()
export class UserService {
  #user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  user$: Observable<User> = this.#user$.asObservable();

  private readonly USER_KEY = 'ct-uid';
  private readonly DOMAIN = '.cloudtalk.io';

  constructor(private cookie: CookieService) {
    this.#user$.next(this.getUser());
  }

  /**
   * Precita usera z cookie
   * @returns {User}
   */
  getUser(): User {
    try {
      const loadedUser = this.cookie.getCookie(this.USER_KEY);
      if (loadedUser) {
        const payload = JSON.parse(loadedUser);
        if (payload) {
          return payload;
        }
      }
      return null;
    } catch (e) {
      LoggerUtil.error('[UserService]: Error while getting user', {}, e);
      return null;
    }
  }

  /**
   * Ulozi usera do cookie
   * @param token
   */
  setUser(token: string): User {
    const userData = parseJwt(token);
    if (!userData) {
      return null;
    }

    if (!userData.id) {
      throw new Error('User id is missing');
    }
    try {
      userData.name = decodeEntities(userData.name);

      if (typeof userData.company === 'string') {
        userData.company = JSON.parse(userData.company);
      }

      if (typeof userData.features === 'string') {
        userData.features = JSON.parse(userData.features);
      }
      userData.outbound_id = +userData.outbound_id;
      userData.company_id = +userData.company_id;
      userData.id = +userData.id;
      // user object is updated during the load time of app, refresh token would remove properties that have been added during intialization
      const oldUserObject = this.getUser();
      if (oldUserObject?.features) {
        userData.features = { ...oldUserObject.features, ...userData.features };
      }

      this.cookie.setCookie({
        ...this.getSetCookiesOptions(),
        value: JSON.stringify(userData),
      });

      this.#user$.next(userData);
    } catch (e) {
      LoggerUtil.error('[UserService]: Error while setting user', {}, e);
    }
    return userData;
  }

  /**
   * Nastavi property userovi
   * @param propName
   * @param value
   */
  setProperty(propName: string, value: any): void {
    try {
      const loadedUserString = this.cookie.getCookie(this.USER_KEY);
      if (loadedUserString) {
        const userData = JSON.parse(loadedUserString);
        if (userData[propName] !== value) {
          // if it's same object, do not update
          if (value instanceof Object && isEqual(value, userData[propName])) {
            return;
          }
          if (userData[propName] instanceof Object && value instanceof Object) {
            userData[propName] = { ...userData[propName], ...value };
          } else {
            userData[propName] = value;
          }

          this.cookie.setCookie({
            ...this.getSetCookiesOptions(),
            value: JSON.stringify(userData),
          });

          this.#user$.next(userData);
        }
      }
    } catch (e) {
      LoggerUtil.error(
        '[UserService]: Error while setting user property',
        {
          propName,
          value,
        },
        e,
      );
    }
  }

  /**
   * Vymaze usera a da vediet ostatnym
   */
  removeUser(): void {
    this.#user$.next(null);
  }

  /**
   * Z dat o pouzivatelovi precita ci ma feature aktivnu
   * @param featureName
   * @returns boolean
   */
  isFeatureEnabled(featureName: string): boolean {
    const user = this.getUser();
    if (user && user.features) {
      if (user.features[featureName] === true) {
        return true;
      }
    }
    return false;
  }

  private getSetCookiesOptions(): Omit<SetCookieType, 'value'> {
    return {
      name: this.USER_KEY,
      expireDays: 365,
      path: '/',
      partitioned: true,
    };
  }
}
