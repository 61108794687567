import { EventEmitter, Injectable } from '@angular/core';
import { CallTag } from '@cloudtalk/sip-service';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

import { LoadStateEnum } from '../../_core/enums/load-state.enum';
import {
  LoadedDataState,
  LoadedNoSuccessData,
} from '../../_core/interfaces/loading-status.interface';
import { CallTagsRepository } from './call-tags.repository';

export interface TagChanges {
  id: string;
  activeTags: CallTag[];
}

@Injectable()
export class CallTagsService {
  tagsChanges$: EventEmitter<TagChanges> = new EventEmitter();

  constructor(private readonly callTagsRepository: CallTagsRepository) {}

  tagsChanged(tagChanges: TagChanges): void {
    this.tagsChanges$.emit(tagChanges);
  }

  loadAllTags(path: string): Observable<LoadedDataState<CallTag[]>> {
    return this.callTagsRepository.loadAllTags(path).pipe(
      map(tagRes => {
        return {
          data: tagRes,
          status: tagRes ? LoadStateEnum.LOADED : LoadStateEnum.ERROR,
        } as LoadedDataState<CallTag[]>;
      }),
      startWith<LoadedDataState<CallTag[]>>({
        status: LoadStateEnum.LOADING,
      }),
      catchError(
        (): Observable<LoadedNoSuccessData> =>
          of({
            status: LoadStateEnum.ERROR,
          }),
      ),
    );
  }
}
