import { EventEmitter, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AfterCallContentIdsEnum } from 'test-automation';

import { EndpointService } from '../../_core/services/networking/endpoint.service';
import { RealtimeTriggersService } from '../../_core/services/realtime-triggers.service';

export interface NoteChanges {
  id: string;
  note: Note;
}

export interface Note {
  id: number;
  cdr_id: number;
  call_uuid: number;
  note: string;
}

export interface CallNoteAppend {
  cdrId: string;
  content?: string;
}

export type CallType = 'call' | 'cdr';

@Injectable()
export class CallNoteService {
  afterCallContentIdsEnum = AfterCallContentIdsEnum;

  public notesChanges: EventEmitter<NoteChanges> = new EventEmitter();

  constructor(
    private endpointService: EndpointService,
    private realtimeTriggersService: RealtimeTriggersService,
  ) {}

  public notesChanged(id, note) {
    this.notesChanges.emit({ id, note });
  }

  public getNote(callId: string, callType: CallType) {
    return this.endpointService._get<Note>(
      'call-notes/view/' + callType + '/' + callId,
    );
  }

  public addNote(
    callId: string,
    callUuid: string,
    cdrId: string | null,
    callType: CallType,
    callNote: { note: string },
  ) {
    this.notesChanged(callId, callNote);
    return this.endpointService
      ._post<Note, unknown>(
        `call-notes/add/${callType}/${callId}`,
        {
          note: callNote.note,
        },
        false,
      )
      .pipe(
        tap(() => {
          if (this.realtimeTriggersService.isEnabled) {
            this.realtimeTriggersService.notifyWebhooksAboutCallModified({
              callUuid,
              cdrId,
            });
          }
        }),
      );
  }

  public editNote(
    noteId: number,
    callUuid: string,
    cdrId: string,
    callNote: { note: string },
  ) {
    this.notesChanged(callUuid || cdrId, callNote);
    return this.endpointService
      ._post(
        `call-notes/edit/${noteId}`,
        {
          note: callNote.note,
        },
        false,
      )
      .pipe(
        tap(() => {
          if (this.realtimeTriggersService.isEnabled) {
            this.realtimeTriggersService.notifyWebhooksAboutCallModified({
              callUuid,
              cdrId,
            });
          }
        }),
      );
  }
}
