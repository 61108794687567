import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { LoggerUtil } from '../../../_shared/utils/logger.util';
import { CookieService } from '../../cookie.service';
import { EnvironmentService } from '../environment.service';
import { VoiceProviderService } from './voice-provider.service';

interface AudioCookie {
  audioInput: string;
  audioOutput: string;
  audioSoundOutput: string;
}

export interface AudioDevice {
  value: string;
  label: string;
}

@Injectable()
export class VoiceSettingService {
  availableDevicesChanged: EventEmitter<void> = new EventEmitter<void>();
  allAudioInputs: AudioDevice[] = [];
  allAudioOutputs: AudioDevice[] = [];
  readonly #audioDeviceChange$: Subject<void> = new Subject<void>();
  readonly #mediaAcquired$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  readonly cookieName = 'cldtk-voice' + this.getDomainPrefix();
  #callAudioInput: string = null;
  #callAudioOutput: string = null;
  #audioSoundOutput: string = null;

  mediaAcquired$: Observable<boolean> = this.#mediaAcquired$.asObservable();
  audioDeviceChange$: Observable<void> =
    this.#audioDeviceChange$.asObservable();

  constructor(
    private cookieService: CookieService,
    private voiceProvider: VoiceProviderService,
    private environmentService: EnvironmentService,
  ) {
    this.updateDevices();
  }

  getAllAvailableDevices(): void {
    if (!navigator?.mediaDevices?.enumerateDevices) {
      return;
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        if (devices.length < 1) {
          this.#mediaAcquired$.next(false);
        }
        // after that we should change all audio inputs/outputs array
        this.parseDevices(devices);
        // after new devices are available, we need load user default settings
        const userSelectedDevices: AudioCookie = this.loadCookies();
        // next we need check if our "preferred" devices are available if not, we will use default
        this.checkAvailabilityOfDevice(userSelectedDevices);
      })
      .catch(err => {
        console.error(err);
      });
  }

  parseDevices(deviceInfos): void {
    // Handles being called several times to update labels. Preserve values.
    const audioOutputOptions = [];
    const audioInputOptions = [];

    deviceInfos.forEach(deviceInfo => {
      const option: AudioDevice = {
        value: deviceInfo.deviceId,
        label:
          deviceInfo.label ||
          (deviceInfo.kind === 'audioinput'
            ? `microphone ${audioInputOptions.length + 1}`
            : `speaker ${audioOutputOptions.length + 1}`),
      };

      if (deviceInfo.kind === 'audioinput') {
        audioInputOptions.push(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        audioOutputOptions.push(option);
      }
    });

    if (audioInputOptions?.length > 0) {
      this.allAudioInputs = audioInputOptions;
    }
    if (audioOutputOptions?.length > 0) {
      this.allAudioOutputs = audioOutputOptions;
    }
  }

  updateDevices(): void {
    this.getAllAvailableDevices();
  }

  checkAvailabilityOfDevice(userSelectedDevices: AudioCookie): void {
    this.callAudioInput = this.getDeviceValue(
      userSelectedDevices.audioInput,
      this.allAudioInputs,
    );
    this.callAudioOutput = this.getDeviceValue(
      userSelectedDevices.audioOutput,
      this.allAudioOutputs,
    );
    this.audioSoundOutput = this.getDeviceValue(
      userSelectedDevices.audioSoundOutput,
      this.allAudioOutputs,
    );

    this.checkPermissionOfSelectedMedia();
    this.availableDevicesChanged.emit();
  }

  private getDeviceValue(
    selectedDeviceValue: string,
    availableDevices: AudioDevice[],
  ): string {
    // if our selected device (after load cookies) is not available we need update id to null
    if (
      !availableDevices.some(device => device.value === selectedDeviceValue)
    ) {
      return availableDevices.length > 0 ? availableDevices[0].value : null;
    }
    return selectedDeviceValue;
  }

  /**
   * Microphone
   * */
  get callAudioInput(): string {
    return this.#callAudioInput;
  }

  set callAudioInput(value: string) {
    if (this.#callAudioInput === value) {
      // if we have same value emit change for stream update (
      // case of 'default') the device was changed but it is still default choice
      this.#audioDeviceChange$.next();
      return;
    }
    this.#callAudioInput = value;
    this.#audioDeviceChange$.next();
  }

  /**
   * Audio output pre hovory
   * @return {string}
   */
  get callAudioOutput(): string {
    return this.#callAudioOutput;
  }

  set callAudioOutput(value: string) {
    if (this.#callAudioOutput === value) {
      return;
    }
    this.#callAudioOutput = value;
    this.attachSinkId(this.voiceProvider.call_audio, value);
    this.#audioDeviceChange$.next();
  }
  get audioSoundOutput(): string {
    return this.#audioSoundOutput;
  }

  set audioSoundOutput(value: string) {
    if (this.#audioSoundOutput === value) {
      return;
    }
    this.#audioSoundOutput = value;
    this.attachSinkId(
      this.voiceProvider.audioForNotifications,
      this.audioSoundOutput,
    );
  }

  toString(): string {
    return JSON.stringify({
      audioInput: this.#callAudioInput,
      audioOutput: this.#callAudioOutput,
      audioSoundOutput: this.#audioSoundOutput,
    });
  }

  save(): void {
    this.cookieService.deleteCookie(this.cookieName, null, '/p');
    this.cookieService.deleteCookie(this.cookieName, null, '/');
    this.cookieService.setCookie({
      name: this.cookieName,
      value: this.toString(),
      expireDays: 365 * 10,
      path: '/',
      partitioned: true,
    });
  }

  /**
   * Attach audio output device to video element using device/sink ID.
   * @param element
   * @param output
   */
  attachSinkId(element, output): void {
    if (typeof element.sinkId !== 'undefined') {
      const originSrc = element.src;
      element.src = null;
      element
        .setSinkId(output)
        .then(() => (element.src = originSrc))
        .catch(() => (element.src = originSrc));
    } else {
      LoggerUtil.warn('Browser does not support output device selection.');
    }
  }

  public checkPermissionOfSelectedMedia(): void {
    const constr = {
      audio: this.callAudioInput
        ? { deviceId: { exact: this.callAudioInput } }
        : true,
      video: false,
    };

    navigator.mediaDevices
      .getUserMedia(constr)
      .then(() => {
        this.#mediaAcquired$.next(true);
      })
      .catch(e => {
        this.#mediaAcquired$.next(false);
        LoggerUtil.info('MIS: Microphone target error #2', {
          tags: { exceptionString: e.toString(), call_uuid: null },
          extraContent: { exceptionString: JSON.stringify(e) },
        });
      });
  }

  loadCookies(): AudioCookie {
    const cookie = this.cookieService.getCookie(this.cookieName);
    if (cookie) {
      return JSON.parse(cookie);
    }
    return { audioInput: null, audioOutput: null, audioSoundOutput: null };
  }

  private getDomainPrefix() {
    let prefix = '';
    if (this.environmentService.isE2E()) {
      prefix = '-e2e';
    } else if (this.environmentService.isStage()) {
      prefix = '-stage';
    }

    return prefix;
  }
}
