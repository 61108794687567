import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { AttenderUser } from '@cloudtalk/sip-service';

import { SmsSelectContactComponentPurposeEnum } from '../sms-select-contact/enums/sms-select-contant-component-purpose.enum';
import { OverlayTypesType } from '../_main-overlay/overlay-types';
import { ContactActivities } from '../../../_core/models/activity';
import { Contact } from '../../../_core/models/contact';
import { CallHistoryItem } from '../../../_core/models/call-history-item.model';
import { MessageSentViaType } from '../../../_core/enums/sms/message-sent-via.enum';

export type OverlayInputItemType =
  | AddEditComponentItem
  | OverlayInputItem
  | SmsTemplateComponentItem;

interface OverlayInputItemBase {
  overlayType?: OverlayTypesType;
}

export interface OverlayInputItem {
  overlayType?: OverlayTypesType; // used only on overlayService
  id?: number; // contact-detail.component
  contact?: Contact; // contact-detail.component
  activities?: ContactActivities; // activities-list.component
  callHistoryItem?: CallHistoryItem;
  callUUID?: string;
  cdrId?: string;
  number?: string;
  type?: string;
  initState?: { rating: number }; // call-quality-rating.component
  isNew?: boolean;
  withExternalNumberInput?: string;
  sentVia?: MessageSentViaType;
  withExternalNumber?: string;
  showContacts?: boolean;
  isCalledFromExistingConversation?: boolean;
  attendedUsersOnMyCall?: AttenderUser[];
  smsSelectContactComponentPurpose?: SmsSelectContactComponentPurposeEnum; // sms-select-contact.component
  contactQuery?: string; // sms-select-contact.component
  supportWarmTransfer?: boolean;
}

export interface AddEditComponentItem extends OverlayInputItemBase {
  id?: number;
  contact?: Contact;
  callUUID?: string;
  phoneNumber?: string;
}

export interface SmsTemplateComponentItem extends OverlayInputItemBase {
  disableSelectingTemplate?: boolean;
}

export interface OverlayItemComponent<
  T extends OverlayInputItemType = OverlayInputItem,
> {
  item?: T;
  closed?: EventEmitter<any>;
}

export class OverlayItem {
  afterClosed$ = new Subject<any>();

  constructor(public item: OverlayInputItem) {}

  afterClosed(): Subject<any> {
    return this.afterClosed$;
  }
}
