import { Injectable } from '@angular/core';
import { CallTag } from '@cloudtalk/sip-service';
import { Observable } from 'rxjs';

import { CTResponse } from '../../_core/interfaces/ctresponse.interface';
import { LoadedDataState } from '../../_core/interfaces/loading-status.interface';
import { EndpointService } from '../../_core/services/networking/endpoint.service';

export interface TagChanges {
  id: string;
  activeTags: CallTag[];
}

@Injectable()
export class CallTagsRepository {
  constructor(private endpointService: EndpointService) {}

  loadAllTags(path: string): Observable<LoadedDataState<CallTag[]>> {
    return this.endpointService._get(`call-tags/${path}`);
  }

  loadCallTags(cdrId: number): Observable<CallTag[]> {
    return this.endpointService._get(`call-tags/view/cdr/${cdrId}`);
  }

  loadCallTagsWithUuid(uuid: string): Observable<CallTag[]> {
    return this.endpointService._get(`call-tags/view/call/${uuid}`);
  }

  saveTags(
    currentTagType: string,
    currentTagId: string,
    tagIds: number[],
  ): Observable<CTResponse<string>> {
    return this.endpointService._patch<string, { call_tag_id: number[] }>(
      `call-tags/add/${currentTagType}/${currentTagId}`,
      {
        call_tag_id: tagIds,
      },
      false,
    );
  }
}
